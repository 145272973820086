import React from "react";

import { classList } from "../../lib/helpers";
import * as styles from "./Button.module.scss";

export type ButtonStyle =
  | "primary"
  | "secondary"
  | "outline_inverted"
  | "currentColor_inverted";
export interface ButtonProps {
  id?: string;
  type?: "button" | "submit";
  btnStyle?: ButtonStyle;
  size?: "base" | "small";
  label: string | React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  id,
  type = "button",
  btnStyle = "primary",
  size = "base",
  icon,
  label,
  disabled,
  ...props
}) => {
  const classes = classList(styles.button, styles[btnStyle], styles[size]);
  return (
    <button
      id={id}
      type={type}
      className={classes}
      disabled={disabled}
      {...props}
    >
      {icon && <i>{icon}</i>}
      <span>{label}</span>
    </button>
  );
};
