// extracted by mini-css-extract-plugin
export var section = "Section-module--section--5iaF4";
export var section_spaceTop_overlap = "Section-module--section_spaceTop_overlap--mh5Ef";
export var section_spaceTop_none = "Section-module--section_spaceTop_none--zMfrI";
export var section_spaceTop_default = "Section-module--section_spaceTop_default--4-Jz3";
export var section_spaceTop_large = "Section-module--section_spaceTop_large--dQ-hb";
export var section_spaceBottom_overlap = "Section-module--section_spaceBottom_overlap--Wpk6a";
export var section_spaceBottom_none = "Section-module--section_spaceBottom_none--eQxT2";
export var section_spaceBottom_default = "Section-module--section_spaceBottom_default--05kJG";
export var section_spaceBottom_large = "Section-module--section_spaceBottom_large--LyhPS";
export var section_width_default = "Section-module--section_width_default--jN6df";
export var section_width_narrow = "Section-module--section_width_narrow--i6c8o";
export var section_width_full = "Section-module--section_width_full--MOI7u";
export var section_bgColor_black = "Section-module--section_bgColor_black--2I7vV";
export var section_bgColor_casioBlue = "Section-module--section_bgColor_casioBlue--FJ-hv";
export var section_bgColor_blue = "Section-module--section_bgColor_blue--H6l7r";
export var section_bgColor_blueDark = "Section-module--section_bgColor_blueDark--zoYNc";
export var section_bgColor_lightGrey = "Section-module--section_bgColor_lightGrey--gCJ3M";
export var section_bgColor_yellow = "Section-module--section_bgColor_yellow--TNKaz";
export var section_bgColor_yellowDark = "Section-module--section_bgColor_yellowDark--JjnLK";
export var section_bgColor_green = "Section-module--section_bgColor_green--rSMbj";
export var section_bgColor_cyan = "Section-module--section_bgColor_cyan--Ehe3s";
export var section_bgColor_cyanDark = "Section-module--section_bgColor_cyanDark--gPJde";
export var section_bgColor_greenDark = "Section-module--section_bgColor_greenDark--hM6Br";
export var section_bgColor_purple = "Section-module--section_bgColor_purple--QwW1L";
export var section_bgColor_purpleDark = "Section-module--section_bgColor_purpleDark--BxsGY";
export var section_bgColor_paleRed = "Section-module--section_bgColor_paleRed--B0W4m";
export var section_bgColor_paleRedDark = "Section-module--section_bgColor_paleRedDark--kv0G1";
export var section_bgColor_magenta = "Section-module--section_bgColor_magenta--XV1db";
export var section_bgColor_magentaDark = "Section-module--section_bgColor_magentaDark--EWqO7";
export var section_bgColor_orange = "Section-module--section_bgColor_orange--MAPrd";
export var section_bgColor_orangeDark = "Section-module--section_bgColor_orangeDark--icECF";
export var section_bgColor_brown = "Section-module--section_bgColor_brown--LWWKI";
export var section_bgColor_brownDark = "Section-module--section_bgColor_brownDark--CnadD";