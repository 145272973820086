import { layout } from "./Layout.module.scss";

import AppleTouchIcon from "../../apple-touch-icon.png";
import Favicon from "../../favicon.ico";
import Favicon16 from "../../favicon-16x16.png";
import Favicon32 from "../../favicon-32x32.png";
import { Helmet } from "react-helmet";
import React from "react";

export interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
      </Helmet>
      <div className={layout}>{children}</div>
    </>
  );
};
