import React from "react";

import { classList } from "../../lib/helpers";
import * as styles from "./Section.module.scss";
import * as blockContentStyles from "../BlockContent/BlockContent.module.scss";

export type SectionSettingsData = {
  backgroundColor?: string[];
  width?: SectionWidth;
  spacingTop?: string;
  spacingBottom?: string;
};

export type SectionType = "default" | "narrow" | "full";

export enum SectionWidth {
  DEFAULT = "default",
  NARROW = "narrow",
  FULL = "full",
}

export enum SectionSpace {
  OVERLAP = "overlap",
  NONE = "none",
  DEFAULT = "default",
  LARGE = "large",
}

export interface SectionProps extends SectionSettingsData {
  className?: string;
  children: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({
  children,
  className,
  backgroundColor,
  width = SectionWidth.DEFAULT,
  spacingTop = SectionSpace.DEFAULT,
  spacingBottom = SectionSpace.DEFAULT,
}) => {
  const classes = classList(
    styles.section,
    styles["section_width_" + width],
    styles["section_spaceTop_" + spacingTop],
    styles["section_spaceBottom_" + spacingBottom],
    backgroundColor && styles["section_bgColor_" + backgroundColor[0]],
    backgroundColor && blockContentStyles.section_filled,
    className && className
  );

  return (
    <section className={classes}>
      <div>{children}</div>
    </section>
  );
};
