module.exports = {
  classList: (...classes) => {
    return classes.filter(Boolean).join(" ");
  },
  stripSlashes: (slug) => {
    if (!slug) return slug;
    if (slug == "/") return "";
    const start = slug[0] == "/" ? 1 : 0;
    const end = slug[slug.length - 1] == "/" ? slug.length - 1 : slug.length;
    return slug.substring(start, end);
  },
};
